export enum Feature {
    Spotlight = "SPOTLIGHT",
    ArtistStudio = "ARTIST_STUDIO",
    MOD_INGRESS = "MOD_INGRESS",
    MerchCuration = "CURATE_MERCH",
    CsvExport = "CSV_EXPORT",
    PROMO_SHARE_CARD = "PROMO_SHARE_CARD",
}

export enum GlobalFeature {
    // Adding a test feature here until we add real features
    TEST_FEATURE_GLOBAL_SCOPE = "TEST_FEATURE_GLOBAL_SCOPE",
    INTERCOM = "INTERCOM",
    COOKIE_CONSENT = "COOKIE_CONSENT",
    WOODSTOCK = "WOODSTOCK",
    // 3P Features
    INSTAGRAMV3 = "INSTAGRAMV3",
    TWITTER = "TWITTER",
    CDBABY = "CDBABY",
    TUNECORE = "TUNECORE",
    TUNECORE_JP = "TUNECORE_JP",
    DISTROKID = "DISTROKID",
    TWITCH = "TWITCH",
}

export enum FeatureEffect {
    ALLOWED = "ALLOWED",
    BLOCKED = "BLOCKED",
}

export interface FeaturePermission {
    effect: FeatureEffect;
    feature: Feature;
}

export interface GlobalFeaturePermission {
    effect: FeatureEffect;
    feature: GlobalFeature;
}
