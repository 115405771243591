export enum OAuthPartners {
    CDBABY = "CDBABY",
    DISTROKID = "DISTROKID",
    TUNECORE = "TUNECORE",
    TUNECOREJP = "TUNECOREJP",
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
    INSTAGRAMV3 = "INSTAGRAMV3",
    TWITCH = "TWITCH",
}

export type OAuth3PStateTokens = Record<OAuthPartners, string | undefined>;

export const initialOAuth3PStateTokens: OAuth3PStateTokens = {
    CDBABY: undefined,
    DISTROKID: undefined,
    TUNECORE: undefined,
    TUNECOREJP: undefined,
    FACEBOOK: undefined,
    TWITTER: undefined,
    INSTAGRAMV3: undefined,
    TWITCH: undefined,
};

export type StateTokensInProgressStatus = Record<OAuthPartners, boolean>;

export const initialStateTokensInProgress: StateTokensInProgressStatus = {
    CDBABY: false,
    DISTROKID: false,
    TUNECORE: false,
    TUNECOREJP: false,
    FACEBOOK: false,
    TWITTER: false,
    INSTAGRAMV3: false,
    TWITCH: false,
};

export enum SocialAccountPartners {
    TWITTER = "TWITTER",
    INSTAGRAMV3 = "INSTAGRAMV3",
}

export type SocialAccountData = Record<
    SocialAccountPartners,
    string | undefined
>;

export const initialSocialAccountData: SocialAccountData = {
    INSTAGRAMV3: undefined,
    TWITTER: undefined,
};
