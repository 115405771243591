import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dropdown, Row, Container, Col } from "react-bootstrap";
import { Dispatch, AnyAction } from "redux";
import serialize from "serialize-javascript";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import * as rootStyles from "../../styles";
import {
    artist,
    JobTitle,
    ErrorPayload,
    claimRequestPayload,
    METRIC_KEYS,
    telemetryPayload,
    PartnerName,
    oAuthClientIds,
    EntityType,
    clientMetricsPayload,
    BundleMap,
    ModalRenderFunction,
    GlobalModal,
    OAuth3PStateTokens,
    GetStateTokenPayload,
    OAuthPartners,
    teamInfo,
    StateTokensInProgressStatus,
    GetSocialAccountDataPayload,
    SocialAccountData,
} from "../../../models";
import {
    getLocalizedString,
    paths,
    translateJobTitleToString,
    testIDSuffixes,
    GENERIC_TEXT_FIELD_LIMIT,
    MOREINFO_TEXT_FIELD_LIMIT,
    buildUIClickPayload,
} from "../../../utils";
import {
    ClaimTakeMeToVendorLabelFlowModal,
    LargeSolidButton,
    TextField,
    TwitterClaimWarningModal,
    ViewConnectedSocialAccountsModal,
} from "../../components";
import {
    IconsList,
    Icon,
    styledTitle,
    ImageWithFallback,
    GoBackButton,
    ClaimHeaderWithBackButton,
    LargeGlassButton,
} from "../../components/common";
import {
    artistClaimActions,
    RootState,
    outageStatusActions,
    telemetryActions,
    userActions,
    clientMetricsActions,
    globalNotificationsActions,
    oAuthActions,
} from "../../../store";
import { getThirdPartyEndpoints, generateState } from "../../../service/common";
import { dropDownStyles, buttonStyles } from "../../styles/buttonStyles";
import { ConfirmNoSocialMediaModal } from "../../components";
import { getBundleMap } from "../../../store/selectors/commonSelectors";
import { webFontSizes, colors } from "../../styles";
import { ErrorModal } from "../../components/common/error";
import {
    get3PStateTokens,
    get3PStateTokensInProgress,
    getBlockedCdBabyButton,
    getBlockedDistrokidButton,
    getBlockedInstagramButton,
    getBlockedTunecoreButton,
    getBlockedTunecoreJpButton,
    getBlockedTwitterButton,
} from "../../../store/selectors/oAuthSelectors";

const testIDPrefix = "ProvideYourInformationScreen";
const metricPrefix = "provideYourInformationPage";
const CLAIM_STATE = "claimState";
const CLAIM_ARTIST_ASIN = "claimArtistAsin";

const jobTitles: JobTitle[] = [
    JobTitle.Artist,
    JobTitle.Manager,
    JobTitle.LabelRep,
];
const thirdPartyUrls = getThirdPartyEndpoints();

type ViewProps = {
    partner: string;
    token: string;
    stateId?: string;
    nounce?: string;
};

type StateProps = {
    signedIn: boolean;
    claimSubmitted: boolean;
    error?: ErrorPayload;
    inProgress: boolean;
    selectedArtist?: artist;
    socialAccountData?: SocialAccountData;
    oAuthClientIds?: oAuthClientIds;
    bundleMap: BundleMap;
    stateTokens: OAuth3PStateTokens;
    stateTokensInProgress: StateTokensInProgressStatus;
    blockInstagramButton: boolean;
    blockTwitterButton: boolean;
    blockCdBabyButton: boolean;
    blockTunecoreButton: boolean;
    blockTunecoreJpButton: boolean;
    blockDistrokidButton: boolean;
};

type DispatchProps = {
    claimArtist: (payload: claimRequestPayload) => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    refreshOutageStatus: () => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    requestModal: (payload: ModalRenderFunction) => void;
    getStateToken: (payload: GetStateTokenPayload) => void;
    setHideIntercomLauncher: (payload: boolean) => void;
    setShowIntercomMessenger: (payload: boolean) => void;
    getSocialAccountData: (payload: GetSocialAccountDataPayload) => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps<ViewProps>;
type AuthState = {
    stateId?: string;
    token?: string;
};

type State = {
    company: string;
    jobTitle?: JobTitle;
    moreInfo: string;
    submitted: boolean;
    fbData: AuthState;
    twitterData: AuthState;
    cdBabyData: AuthState;
    distrokidData: AuthState;
    tunecoreData: AuthState;
    tunecoreJPData: AuthState;
    instagramData: AuthState;
    errorMessage: string;
    instagramUserName: string | undefined;
    twitterUserName: string | undefined;
};

class ProvideYourInformationScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let claimState = undefined;
        if (
            localStorage.getItem(CLAIM_ARTIST_ASIN) ===
            this.props.selectedArtist?.asin
        ) {
            claimState = localStorage.getItem(CLAIM_STATE);
        } else {
            this.removeState();
        }

        const storedState = claimState && JSON.parse(claimState);

        this.state = storedState
            ? storedState
            : {
                  moreInfo: "",
                  company: "",
                  submitted: false,
                  cdBabyData: {},
                  distrokidData: {},
                  tunecoreData: {},
                  tunecoreJPData: {},
                  instagramData: {},
                  twitterData: {},
                  errorMessage: "",
              };

        // Add redirect to Twitch incase the root nav redirect doesnt
        if (this.props.match.params.partner === PartnerName.twitch) {
            window.location.replace(
                `${paths.profile}/link/${this.props.match.params.token}`
            );
            return;
        }

        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.provideInformation],
            ]),
        });

        this.props.refreshOutageStatus();

        this.props.setHideIntercomLauncher(false);

        if (!this.props.selectedArtist) {
            this.props.history.push(paths.artistSearch);
        }

        switch (this.props.match.params.partner) {
            case PartnerName.twitter:
                this.setState(
                    { twitterData: { token: this.props.match.params.token } },
                    () => this.storeState()
                );
                break;
            case PartnerName.instagramV3:
                if (
                    this.state.instagramData.stateId !==
                    this.props.match.params.stateId
                ) {
                    console.log("stateId not matching for instagram");
                    return;
                }
                this.setState(
                    { instagramData: { token: this.props.match.params.token } },
                    () => this.storeState()
                );
                break;
            case PartnerName.cdBaby:
                if (
                    this.state.cdBabyData.stateId !==
                    this.props.match.params.stateId
                ) {
                    console.log("stateId not matching for cdBaby");
                    return;
                }
                this.setState(
                    { cdBabyData: { token: this.props.match.params.token } },
                    () => this.storeState()
                );
                break;
            case PartnerName.distrokid:
                if (
                    this.state.distrokidData.stateId !==
                    this.props.match.params.stateId
                ) {
                    console.log("stateId not matching for distrokid");
                    return;
                }
                this.setState(
                    { distrokidData: { token: this.props.match.params.token } },
                    () => this.storeState()
                );
                break;
            case PartnerName.tunecore:
                if (
                    this.state.tunecoreData.stateId !==
                    this.props.match.params.stateId
                ) {
                    console.log("stateId not matching for tunecore");
                    return;
                }
                this.setState(
                    { tunecoreData: { token: this.props.match.params.token } },
                    () => this.storeState()
                );
                break;
            case PartnerName.tunecoreJP:
                if (
                    this.state.tunecoreJPData.stateId !==
                    this.props.match.params.stateId
                ) {
                    console.log("stateId not matching for tunecoreJP");
                    return;
                }
                this.setState(
                    {
                        tunecoreJPData: {
                            token: this.props.match.params.token,
                        },
                    },
                    () => this.storeState()
                );
                break;
        }
    }

    componentWillUnmount() {
        this.props.setHideIntercomLauncher(true);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // If we get an error, display it
        if (this.props.error !== prevProps.error) {
            if (this.props.error !== undefined) {
                this.props.requestModal(this.errorModal);
            }

            const claimLimitErrorMessage =
                this.props.error &&
                this.props.error.exception &&
                this.props.error.exception.response &&
                this.props.error.exception.response.data.message ===
                    "Creating more claims than allowed.";

            this.setState({
                errorMessage: claimLimitErrorMessage
                    ? getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ERRORS_STRINGS,
                          stringId: stringIds.Errors.artistClaimLimitError,
                      })
                    : getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ERRORS_STRINGS,
                          stringId: stringIds.Errors.artistClaimError,
                      }),
            });

            return;
        }

        // Redirect to claim submitted screen once we get response back
        if (!prevProps.claimSubmitted && this.props.claimSubmitted) {
            this.props.history.push(paths.claimDone);
        }
        if (prevProps.stateTokens !== this.props.stateTokens) {
            const getChangedPartner = () => {
                for (const p of Object.keys(prevProps.stateTokens)) {
                    const partner: OAuthPartners =
                        p as keyof OAuth3PStateTokens;
                    if (
                        !prevProps.stateTokens[partner] &&
                        this.props.stateTokens[partner]
                    ) {
                        return partner;
                    }
                }
                return null;
            };
            const changedPartner = getChangedPartner();
            switch (changedPartner) {
                case OAuthPartners.TWITTER:
                    this.openTwitterWebPage();
                    break;
                case OAuthPartners.INSTAGRAMV3:
                    this.openInstagramWebPage();
                    break;
                case OAuthPartners.CDBABY:
                    this.openCdBabyWebPage();
                    break;
                case OAuthPartners.DISTROKID:
                    this.openDistrokidWebPage();
                    break;
                case OAuthPartners.TUNECORE:
                    this.openTunecoreWebPage();
                    break;
                case OAuthPartners.TUNECOREJP:
                    this.openTunecoreJPWebPage();
                    break;
                default:
                    console.log(
                        `No change in 3P state tokens: ${changedPartner}`
                    );
            }
        }

        if (
            this.state.instagramData.token &&
            prevState.instagramData.token !== this.state.instagramData.token
        ) {
            const payload: GetSocialAccountDataPayload = {
                authId: this.state.instagramData.token,
            };
            this.props.getSocialAccountData(payload);
        }

        if (
            this.state.instagramData.token &&
            this.props.socialAccountData?.INSTAGRAMV3 &&
            this.props.socialAccountData?.INSTAGRAMV3 !==
                this.state.instagramUserName
        ) {
            this.setState(
                {
                    instagramUserName: this.props.socialAccountData.INSTAGRAMV3,
                },
                () => this.storeState()
            );
        }

        if (
            this.state.twitterData.token &&
            prevState.twitterData.token !== this.state.twitterData.token
        ) {
            const payload: GetSocialAccountDataPayload = {
                authId: this.state.twitterData.token,
            };
            this.props.getSocialAccountData(payload);
        }

        if (
            this.state.twitterData.token &&
            this.props.socialAccountData?.TWITTER &&
            this.props.socialAccountData?.TWITTER !== this.state.twitterUserName
        ) {
            this.setState(
                {
                    twitterUserName: this.props.socialAccountData.TWITTER,
                },
                () => this.storeState()
            );
        }
    }

    render() {
        const artistName = this.props.selectedArtist?.title;
        const artistImage = this.props.selectedArtist?.images?.artLarge;

        const partnerLogoContainer = (
            <Container>
                <Row style={{ ...logosContainer }}>
                    {/* CD BABY */}
                    {!this.props.blockCdBabyButton && (
                        <Col
                            style={{ ...socialLogoContainer, marginTop: 10 }}
                            xs="auto"
                        >
                            <div
                                style={{
                                    ...partnerLogoButton,
                                    pointerEvents: this.props
                                        .stateTokensInProgress.CDBABY
                                        ? "none"
                                        : "auto",
                                }}
                                onClick={this.getCdBabyStateTokenButtonClick}
                                id={`${testIDPrefix}_cdBabyButton`}
                            >
                                <img
                                    src={ImageList.cdbabyWeb}
                                    style={{
                                        ...partnerLogo,
                                        opacity: this.props
                                            .stateTokensInProgress.CDBABY
                                            ? rootStyles.glass._3
                                            : 1,
                                    }}
                                />
                            </div>
                            {this.state.cdBabyData.token ? (
                                <div
                                    style={checkMarkContainer}
                                    id={`${testIDPrefix}_cdBabyDone`}
                                >
                                    <Icon
                                        size={rootStyles.icons.tiny}
                                        color={rootStyles.colors.accent}
                                        icon={IconsList.action_done}
                                        id={`${testIDPrefix}_CDBabyDoneIcon`}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    )}
                    {/* Distrokid */}
                    {!this.props.blockDistrokidButton && (
                        <Col
                            style={{ ...socialLogoContainer, marginTop: 10 }}
                            xs="auto"
                        >
                            <div
                                style={{
                                    ...partnerLogoButton,
                                    pointerEvents: this.props
                                        .stateTokensInProgress.DISTROKID
                                        ? "none"
                                        : "auto",
                                }}
                                onClick={this.getDistrokidStateTokenButtonClick}
                                id={`${testIDPrefix}_distrokidButton`}
                            >
                                <img
                                    src={ImageList.distrokidWeb}
                                    style={{
                                        ...partnerLogo,
                                        opacity: this.props
                                            .stateTokensInProgress.DISTROKID
                                            ? rootStyles.glass._3
                                            : 1,
                                    }}
                                />
                            </div>
                            {this.state.distrokidData.token ? (
                                <div
                                    style={checkMarkContainer}
                                    id={`${testIDPrefix}_distrokidDone`}
                                >
                                    <Icon
                                        size={rootStyles.icons.tiny}
                                        color={rootStyles.colors.accent}
                                        icon={IconsList.action_done}
                                        id={`${testIDPrefix}_DistrokidDoneIcon`}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    )}
                    {/* Tunecore */}
                    {!this.props.blockTunecoreButton && (
                        <Col
                            style={{ ...socialLogoContainer, marginTop: 10 }}
                            xs="auto"
                        >
                            <div
                                style={{
                                    ...partnerLogoButton,
                                    pointerEvents: this.props
                                        .stateTokensInProgress.TUNECORE
                                        ? "none"
                                        : "auto",
                                }}
                                onClick={this.getTunecoreStateTokenButtonClick}
                                id={`${testIDPrefix}_tunecoreButton`}
                            >
                                <img
                                    src={ImageList.tunecoreWeb}
                                    style={{
                                        ...partnerLogo,
                                        opacity: this.props
                                            .stateTokensInProgress.TUNECORE
                                            ? rootStyles.glass._3
                                            : 1,
                                    }}
                                />
                            </div>
                            {this.state.tunecoreData.token ? (
                                <div
                                    style={checkMarkContainer}
                                    id={`${testIDPrefix}_tunecoreDone`}
                                >
                                    <Icon
                                        size={rootStyles.icons.tiny}
                                        color={rootStyles.colors.accent}
                                        icon={IconsList.action_done}
                                        id={`${testIDPrefix}_TunecoreDoneIcon`}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    )}
                    {/* Tunecore JP */}
                    {!this.props.blockTunecoreJpButton && (
                        <Col
                            style={{ ...socialLogoContainer, marginTop: 10 }}
                            xs="auto"
                        >
                            <div
                                style={{
                                    ...partnerLogoButton,
                                    pointerEvents: this.props
                                        .stateTokensInProgress.TUNECOREJP
                                        ? "none"
                                        : "auto",
                                }}
                                onClick={
                                    this.getTunecoreJPStateTokenButtonClick
                                }
                                id={`${testIDPrefix}_tunecoreJPButton`}
                            >
                                <img
                                    src={ImageList.tunecoreJP}
                                    style={{
                                        ...partnerLogo,
                                        opacity: this.props
                                            .stateTokensInProgress.TUNECOREJP
                                            ? rootStyles.glass._3
                                            : 1,
                                    }}
                                />
                            </div>
                            {this.state.tunecoreJPData.token ? (
                                <div
                                    style={checkMarkContainer}
                                    id={`${testIDPrefix}_tunecoreJPDone`}
                                >
                                    <Icon
                                        size={rootStyles.icons.tiny}
                                        color={rootStyles.colors.accent}
                                        icon={IconsList.action_done}
                                        id={`${testIDPrefix}_TunecoreJPDoneIcon`}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    )}
                </Row>
            </Container>
        );

        const socialMediaLogoContainer = (
            <Container style={{ padding: 0 }}>
                <Row style={{ ...logosContainer }}>
                    {/* Instagram */}
                    {!this.props.blockInstagramButton && (
                        <Col style={socialLogoContainer} xs="auto">
                            <div
                                style={{
                                    ...socialLogoButton,
                                    pointerEvents: this.props
                                        .stateTokensInProgress.INSTAGRAMV3
                                        ? "none"
                                        : "auto",
                                }}
                                onClick={this.getInstagramStateTokenButtonClick}
                                id={`${testIDPrefix}_igButton`}
                            >
                                <img
                                    src={ImageList.igWeb}
                                    style={{
                                        ...socialLogo,
                                        opacity: this.props
                                            .stateTokensInProgress.INSTAGRAMV3
                                            ? rootStyles.glass._3
                                            : 1,
                                    }}
                                />
                            </div>
                            {this.state.instagramData.token ? (
                                <div
                                    style={socialCheckMarkContainer}
                                    id={`${testIDPrefix}_igDone`}
                                >
                                    <Icon
                                        size={rootStyles.icons.tiny}
                                        color={rootStyles.colors.accent}
                                        icon={IconsList.action_done}
                                        id={`${testIDPrefix}_InstagramDoneIcon`}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    )}
                    {/* Twitter */}
                    {!this.props.blockTwitterButton && (
                        <Col style={socialLogoContainer} xs="auto">
                            <div
                                style={{
                                    ...socialLogoButton,
                                    pointerEvents: this.props
                                        .stateTokensInProgress.TWITTER
                                        ? "none"
                                        : "auto",
                                }}
                                onClick={this.onTwitterButtonClick}
                                id={`${testIDPrefix}_twitterButton`}
                            >
                                <img
                                    src={ImageList.xWeb}
                                    style={{
                                        ...socialLogo,
                                        opacity: this.props
                                            .stateTokensInProgress.TWITTER
                                            ? rootStyles.glass._3
                                            : 1,
                                    }}
                                />
                            </div>
                            {this.state.twitterData.token ? (
                                <div
                                    style={socialCheckMarkContainer}
                                    id={`${testIDPrefix}_twitterDone`}
                                >
                                    <Icon
                                        size={rootStyles.icons.tiny}
                                        color={rootStyles.colors.accent}
                                        icon={IconsList.action_done}
                                        id={`${testIDPrefix}_TwitterDoneIcon`}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    )}
                </Row>

                {this.state.instagramData.token ||
                this.state.twitterData.token ? (
                    <Row style={viewSocialAccountContainer}>
                        {/* View connected social accounts */}
                        <Col style={socialLogoContainer} xs="auto">
                            <LargeGlassButton
                                containerStyle={{
                                    ...socialLogoButton,
                                    width: "100%",
                                }}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .viewConnectedAccounts,
                                    }
                                )}
                                onClick={
                                    this
                                        .onViewConnectedSocialAccountsButtonClick
                                }
                                id={
                                    testIDPrefix +
                                    "ViewConnectedSocialAccountButton"
                                }
                            />
                        </Col>
                    </Row>
                ) : null}
            </Container>
        );

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <ClaimHeaderWithBackButton />
                <GoBackButton
                    style={{ alignSelf: "flex-start" }}
                    onClick={() => this.props.history.push(paths.claim)}
                    id={testIDPrefix}
                />
                <Col lg={8} style={formStyle}>
                    <Row style={centerAlign}>
                        <ImageWithFallback
                            style={{
                                ...rootStyles.imageStyles.mediumRoundImage,
                                margin: "auto",
                            }}
                            source={artistImage || ""}
                            fallback={ImageList.placeholder_artist}
                            id={`${testIDPrefix}_ArtistImage`}
                        />
                    </Row>
                    <Row style={centerAlign}>
                        <styledTitle.h2
                            style={artistNameStyle}
                            id={`${testIDPrefix}_ArtistName`}
                        >
                            {artistName}
                        </styledTitle.h2>
                    </Row>
                    <Row style={centerAlign}>
                        <p style={infoText} id={`${testIDPrefix}_InfoText`}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                                stringId: stringIds.ClaimThisArtist.info,
                            })}
                        </p>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: rootStyles.spacers.large }}>
                            <p
                                style={fieldDescriptionText}
                                id={`${testIDPrefix}_CompanyLabel`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation
                                            .companyLabel,
                                })}
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: rootStyles.spacers.large }}>
                        <Col>
                            <TextField
                                label={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .company,
                                    }
                                )}
                                onChange={this.setCompanyText}
                                maxLength={GENERIC_TEXT_FIELD_LIMIT}
                                id={testIDPrefix + "_Company"}
                                defaultValue={this.state.company}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p
                                style={fieldDescriptionText}
                                id={`${testIDPrefix}_Role`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation.role,
                                })}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Dropdown
                                    style={{
                                        ...dropDownStyles.dropdown,
                                        width: "100%",
                                        margin: "auto",
                                    }}
                                >
                                    <Dropdown.Toggle
                                        style={{
                                            ...dropDownStyles.dropdownButton,
                                            width: "100%",
                                        }}
                                        id="roles"
                                    >
                                        <span style={jobTitleStyle}>
                                            {this.state.jobTitle
                                                ? translateJobTitleToString(
                                                      this.state.jobTitle,
                                                      this.props.bundleMap
                                                  )
                                                : getLocalizedString(
                                                      this.props.bundleMap,
                                                      {
                                                          bundleId:
                                                              bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                                          stringId:
                                                              stringIds
                                                                  .ProvideYourInformation
                                                                  .selectOne,
                                                      }
                                                  )}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        style={dropDownStyles.dropdownMenu}
                                    >
                                        {this.getJobTitles()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ marginTop: rootStyles.spacers.large }}>
                            <span
                                style={{
                                    ...rootStyles.textStyles.label,
                                    textTransform: "uppercase",
                                }}
                                id={`${testIDPrefix}_Optional`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation
                                            .optional,
                                })}
                            </span>
                        </Col>
                    </Row>

                    <Row style={centerAlign}>
                        <styledTitle.h4
                            style={sectionHeaderStyle}
                            id={`${testIDPrefix}_FastTrack`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation.fastTrack,
                            })}
                        </styledTitle.h4>

                        <span
                            style={centeredText}
                            id={`${testIDPrefix}_HelpExpedite`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .helpExpedite,
                            })}
                        </span>
                    </Row>

                    <Row style={centerAlign}>
                        <div style={roundNumericalSeparator}>
                            <span style={separatorText}>1</span>
                        </div>
                    </Row>

                    {/** Social */}
                    <Row style={centerAlign}>
                        <styledTitle.h4
                            style={sectionHeaderStyle}
                            id={`${testIDPrefix}_EnterSocial`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .enterSocial,
                            })}
                        </styledTitle.h4>
                        <p
                            style={centeredText}
                            id={`${testIDPrefix}_SignIntoSocial`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .signIntoSocial,
                            })}
                        </p>
                    </Row>

                    {socialMediaLogoContainer}

                    <Row style={centerAlign}>
                        <div style={roundNumericalSeparator}>
                            <span style={separatorText}>2</span>
                        </div>
                    </Row>

                    {/** Distributor */}
                    <Row style={centerAlign}>
                        <styledTitle.h4
                            style={sectionHeaderStyle}
                            id={`${testIDPrefix}_FastTrack`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .connectDistributor,
                            })}
                        </styledTitle.h4>
                        <p
                            style={centeredText}
                            id={`${testIDPrefix}_ToGetFastest`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .toGetFastest,
                            })}
                        </p>
                    </Row>

                    {partnerLogoContainer}

                    <Row style={centerAlign}>
                        <div style={roundNumericalSeparator}>
                            <span style={separatorText}>3</span>
                        </div>
                    </Row>

                    {/** Additional Info */}
                    <Row style={centerAlign}>
                        <styledTitle.h4
                            style={sectionHeaderStyle}
                            id={`${testIDPrefix}_PleaseListTheArtist`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .pleaseListTheArtist,
                            })}
                        </styledTitle.h4>
                        <p
                            style={centeredText}
                            id={`${testIDPrefix}_PleaseListAWebsite`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .pleaseListAWebsite,
                            })}
                        </p>
                    </Row>

                    <Row
                        style={{
                            width: "100%",
                            marginTop: rootStyles.spacers.large,
                        }}
                    >
                        <TextField
                            multiLine={true}
                            onChange={this.setOtherText}
                            maxLength={MOREINFO_TEXT_FIELD_LIMIT}
                            id={testIDPrefix + "_PleaseListTheArtistTextField"}
                            defaultValue={this.state.moreInfo}
                            placeholder={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation
                                            .pleaseListTheArtist,
                                }
                            )}
                        />
                    </Row>

                    <Row
                        style={{
                            ...centerAlign,
                            marginTop: rootStyles.spacers.epic,
                            marginBottom: rootStyles.spacers.epic * 2,
                        }}
                    >
                        <LargeSolidButton
                            containerStyle={{
                                alignSelf: "center",
                                width: 160,
                                opacity:
                                    !this.state.jobTitle ||
                                    !this.state.company ||
                                    this.props.inProgress
                                        ? 0.6
                                        : 1,
                                minHeight: 40,
                            }}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.submit,
                            })}
                            onClick={
                                !this.state.jobTitle ||
                                !this.state.company ||
                                this.props.inProgress
                                    ? undefined
                                    : this.trySubmitClaim
                            }
                            id={testIDPrefix + testIDSuffixes.submit}
                        />
                    </Row>
                </Col>
            </Container>
        );
    }

    private getJobTitles = () => {
        return jobTitles.map((title: JobTitle) => {
            return (
                <Dropdown.Item
                    eventKey={title}
                    onSelect={() => this.selectJobTitle(title)}
                    style={dropDownStyles.dropdownItem}
                >
                    {translateJobTitleToString(title, this.props.bundleMap)}
                </Dropdown.Item>
            );
        });
    };

    private trySubmitClaim = () => {
        if (!this.props.signedIn || !this.state.jobTitle) {
            return;
        }

        if (
            !this.state.twitterData?.token &&
            !this.state.cdBabyData?.token &&
            !this.state.distrokidData?.token &&
            !this.state.tunecoreData?.token &&
            !this.state.tunecoreJPData?.token &&
            !this.state.instagramData?.token
        ) {
            this.props.requestModal(this.noSocialMediaModal);
            return;
        }
        this.submitClaim();
    };

    private submitClaim = () => {
        if (!this.props.signedIn || !this.state.jobTitle) {
            return;
        }

        this.props.userAction({
            name: metricPrefix + "SubmitClaimButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist?.asin],
                [METRIC_KEYS.page, paths.provideInformation],
            ]),
        });

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.ProvideYourInformation.submitClaim,
                pageIds.provideYourInformation,
                this.props.selectedArtist?.asin,
                EntityType.ARTIST
            )
        );

        const artistName = this.props.selectedArtist?.title;
        const artistAsin = this.props.selectedArtist?.asin;
        const artist: artist = {
            title: artistName,
            asin: artistAsin,
        };

        const authIds: string[] = [];

        if (this.state.cdBabyData.token) {
            authIds.push(this.state.cdBabyData.token);
        }

        if (this.state.distrokidData.token) {
            authIds.push(this.state.distrokidData.token);
        }

        if (this.state.twitterData.token) {
            authIds.push(this.state.twitterData.token);
        }

        if (this.state.tunecoreData.token) {
            authIds.push(this.state.tunecoreData.token);
        }

        if (this.state.tunecoreJPData.token) {
            authIds.push(this.state.tunecoreJPData.token);
        }

        if (this.state.instagramData.token) {
            authIds.push(this.state.instagramData.token);
        }

        this.removeState();

        this.props.claimArtist({
            requestPath: paths.provideInformation,
            artistAsin: artist.asin || "",
            description: this.state.moreInfo,
            company: this.state.company,
            jobTitle: this.state.jobTitle,
            authIds: authIds,
        });
    };

    private selectJobTitle = (jobTitle: JobTitle) => {
        if (jobTitle === JobTitle.LabelRep) {
            this.props.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.ClaimVendorLabelJobTitle
                        .claimVendorLabelJobTitleOption,
                    pageIds.provideYourInformation,
                    this.props.selectedArtist?.asin,
                    EntityType.ARTIST
                )
            );
            this.props.requestModal(this.ClaimTakeMeToVendorLabelFlowModal);
        } else {
            this.setState({ jobTitle: jobTitle }, () => this.storeState());
        }
    };

    private setCompanyText = (text: string) => {
        this.setState({ company: text }, () => this.storeState());
    };

    private setOtherText = (text: string) => {
        this.setState({ moreInfo: text }, () => this.storeState());
    };

    private getCdBabyStateTokenButtonClick = () => {
        this.sendButtonClickClientMetric(
            buttonIds.ProvideYourInformation.openCdBaby
        );
        this.emitOAuthClickMetric("OpenCdBabyButtonClick");
        if (this.props.stateTokens.CDBABY) {
            this.openCdBabyWebPage();
        } else {
            this.props.getStateToken({
                partner: OAuthPartners.CDBABY,
            });
        }
    };

    private openCdBabyWebPage = () => {
        if (!this.props.oAuthClientIds || !this.props.stateTokens.CDBABY) {
            this.emitOAuthClickMetric("OpenCdBabyButtonClickFailed");
            return;
        }
        const state_val = generateState(this.props.stateTokens.CDBABY);
        const url = thirdPartyUrls.cdBabyUrl(
            encodeURI(serialize(state_val)),
            this.props.oAuthClientIds.CDBABY
        );

        this.setState({ cdBabyData: { stateId: state_val.stateId } }, () =>
            this.storeAndRedirect(url)
        );
    };

    private getDistrokidStateTokenButtonClick = () => {
        this.sendButtonClickClientMetric(
            buttonIds.ProvideYourInformation.openDistrokid
        );
        this.emitOAuthClickMetric("OpenDistrokidButtonClick");
        if (this.props.stateTokens.DISTROKID) {
            this.openDistrokidWebPage();
        } else {
            this.props.getStateToken({
                partner: OAuthPartners.DISTROKID,
            });
        }
    };

    private openDistrokidWebPage = () => {
        if (!this.props.oAuthClientIds || !this.props.stateTokens.DISTROKID) {
            this.emitOAuthClickMetric("OpenDistrokidButtonClickFailed");
            return;
        }
        const state_val = generateState(this.props.stateTokens.DISTROKID);
        const url = thirdPartyUrls.distrokidUrl(
            encodeURI(serialize(state_val)),
            this.props.oAuthClientIds.DISTROKID
        );
        this.setState({ distrokidData: { stateId: state_val.stateId } }, () =>
            this.storeAndRedirect(url)
        );
    };

    private getTunecoreStateTokenButtonClick = () => {
        this.sendButtonClickClientMetric(
            buttonIds.ProvideYourInformation.openTunecore
        );
        this.emitOAuthClickMetric("OpenTunecoreButtonClick");
        if (this.props.stateTokens.TUNECORE) {
            this.openTunecoreWebPage();
        } else {
            this.props.getStateToken({
                partner: OAuthPartners.TUNECORE,
            });
        }
    };

    private openTunecoreWebPage = () => {
        if (!this.props.oAuthClientIds || !this.props.stateTokens.TUNECORE) {
            this.emitOAuthClickMetric("OpenTunecoreButtonClickFailed");
            return;
        }
        const state_val = generateState(this.props.stateTokens.TUNECORE);
        const url = thirdPartyUrls.tunecoreUrl(
            encodeURI(serialize(state_val)),
            this.props.oAuthClientIds.TUNECORE
        );

        this.setState({ tunecoreData: { stateId: state_val.stateId } }, () =>
            this.storeAndRedirect(url)
        );
    };

    private getTunecoreJPStateTokenButtonClick = () => {
        this.sendButtonClickClientMetric(
            buttonIds.ProvideYourInformation.openTunecoreJP
        );
        this.emitOAuthClickMetric("OpenTunecoreJPButtonClick");
        if (this.props.stateTokens.TUNECOREJP) {
            this.openTunecoreJPWebPage();
        } else {
            this.props.getStateToken({
                partner: OAuthPartners.TUNECOREJP,
            });
        }
    };

    private openTunecoreJPWebPage = () => {
        if (!this.props.oAuthClientIds || !this.props.stateTokens.TUNECOREJP) {
            this.emitOAuthClickMetric("OpenTunecoreJPButtonClickFailed");
            return;
        }
        const state_val = generateState(this.props.stateTokens.TUNECOREJP);
        const url = thirdPartyUrls.tunecoreJPUrl(
            encodeURI(serialize(state_val)),
            this.props.oAuthClientIds.TUNECOREJP
        );

        this.setState({ tunecoreJPData: { stateId: state_val.stateId } }, () =>
            this.storeAndRedirect(url)
        );
    };

    private getInstagramStateTokenButtonClick = () => {
        this.sendButtonClickClientMetric(
            buttonIds.ProvideYourInformation.openInstagram
        );
        this.emitOAuthClickMetric("OpenInstagramButtonClick");
        if (this.props.stateTokens.INSTAGRAMV3) {
            this.openInstagramWebPage();
        } else {
            this.props.getStateToken({
                partner: OAuthPartners.INSTAGRAMV3,
            });
        }
    };

    private openInstagramWebPage = () => {
        if (!this.props.oAuthClientIds || !this.props.stateTokens.INSTAGRAMV3) {
            this.emitOAuthClickMetric("OpenInstagramButtonClickFailed");
            return;
        }
        const state_val = generateState(this.props.stateTokens.INSTAGRAMV3);
        const url = thirdPartyUrls.instagramUrl(
            encodeURI(serialize(state_val)),
            this.props.oAuthClientIds.INSTAGRAMV3
        );

        this.setState({ instagramData: { stateId: state_val.stateId } }, () =>
            this.storeAndRedirect(url)
        );
    };

    private showViewConnectedSocialAccountsModal = (
        dismiss: () => void,
        isVisible: boolean
    ): GlobalModal => {
        return (
            <ViewConnectedSocialAccountsModal
                onDismiss={dismiss}
                isVisible={isVisible}
                bundleMap={this.props.bundleMap}
                instagramUserName={
                    this.state.instagramData.token
                        ? this.state.instagramUserName
                        : undefined
                }
                twitterUserName={
                    this.state.twitterData.token
                        ? this.state.twitterUserName
                        : undefined
                }
            />
        );
    };

    private onViewConnectedSocialAccountsButtonClick = () => {
        this.props.requestModal(this.showViewConnectedSocialAccountsModal);
    };

    private showTwitterLoginWarningModal = (
        dismiss: () => void,
        isVisible: boolean
    ): GlobalModal => {
        return (
            <TwitterClaimWarningModal
                onDismiss={dismiss}
                isVisible={isVisible}
                onConfirm={() => {
                    dismiss();
                    //give modal time to disappear before opening new window
                    setTimeout(this.getTwitterStateTokenButtonClick, 300);
                }}
                bundleMap={this.props.bundleMap}
            />
        );
    };

    private onTwitterButtonClick = () => {
        this.props.requestModal(this.showTwitterLoginWarningModal);
    };

    private getTwitterStateTokenButtonClick = () => {
        this.sendButtonClickClientMetric(
            buttonIds.ProvideYourInformation.openTwitter
        );
        this.emitOAuthClickMetric("OpenTwitterButtonClick");

        if (this.props.stateTokens.TWITTER) {
            this.openTwitterWebPage();
        } else {
            this.props.getStateToken({
                partner: OAuthPartners.TWITTER,
            });
        }
    };

    private openTwitterWebPage = () => {
        if (!this.props.oAuthClientIds || !this.props.stateTokens.TWITTER) {
            this.emitOAuthClickMetric("OpenTwitterButtonClickFailed");
            return;
        }
        const state_val = generateState(this.props.stateTokens.TWITTER);
        const url = thirdPartyUrls.twitterUrl(
            btoa(serialize(state_val)),
            this.props.oAuthClientIds.TWITTER
        );
        this.setState({ twitterData: { stateId: state_val.stateId } }, () =>
            this.storeAndRedirect(url)
        );
    };

    private emitOAuthClickMetric(metricName: string) {
        this.props.userAction({
            name: metricPrefix + metricName,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist?.asin],
                [METRIC_KEYS.page, paths.provideInformation],
            ]),
        });
    }

    private sendButtonClickClientMetric(buttonName: string) {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonName,
                pageIds.provideYourInformation,
                this.props.selectedArtist?.asin,
                EntityType.ARTIST
            )
        );
    }

    private storeAndRedirect = (url: string) => {
        this.storeState();
        window.open(url, "_self");
    };

    private storeState = () => {
        if (
            this.props.selectedArtist !== undefined &&
            this.props.selectedArtist.asin !== undefined
        ) {
            localStorage.setItem(CLAIM_STATE, JSON.stringify(this.state));
            localStorage.setItem(
                CLAIM_ARTIST_ASIN,
                this.props.selectedArtist.asin
            );
        }
    };

    private removeState = () => {
        localStorage.removeItem(CLAIM_ARTIST_ASIN);
        localStorage.removeItem(CLAIM_STATE);
    };

    private errorModal = (
        dismiss: () => void,
        isVisible: boolean
    ): GlobalModal => {
        return (
            <ErrorModal
                isVisible={isVisible}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ERRORS_STRINGS,
                    stringId: stringIds.Errors.errorTitle,
                })}
                description={this.state.errorMessage}
                onDismiss={dismiss}
            />
        );
    };

    private noSocialMediaModal = (
        dismiss: () => void,
        isVisible: boolean
    ): GlobalModal => {
        return (
            <ConfirmNoSocialMediaModal
                isVisible={isVisible}
                onCancel={dismiss}
                onContinue={() => {
                    dismiss();
                    this.submitClaim();
                }}
            />
        );
    };

    private ClaimTakeMeToVendorLabelFlowModal = (
        dismiss: () => void,
        isVisible: boolean
    ): GlobalModal => {
        return (
            <ClaimTakeMeToVendorLabelFlowModal
                isVisible={isVisible}
                onCancel={() => {
                    dismiss();
                    this.props.sendClientMetrics(
                        buildUIClickPayload(
                            buttonIds.ClaimVendorLabelJobTitle
                                .claimVendorLabelJobTitleCancel,
                            pageIds.provideYourInformation,
                            this.props.selectedArtist?.asin,
                            EntityType.ARTIST
                        )
                    );
                }}
                onContinue={() => {
                    dismiss();
                    this.props.sendClientMetrics(
                        buildUIClickPayload(
                            buttonIds.ClaimVendorLabelJobTitle
                                .claimVendorLabelJobTitleContinue,
                            pageIds.provideYourInformation,
                            this.props.selectedArtist?.asin,
                            EntityType.ARTIST
                        )
                    );
                    this.props.history.push({
                        pathname: paths.vendorFlow,
                        state: { goBackPath: paths.provideInformation },
                    });
                }}
            />
        );
    };
}

const artistNameStyle: React.CSSProperties = {
    textAlign: "center",
    marginLeft: -40,
    marginRight: -40,
    lineHeight: 1,
};
const infoText: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    width: "100%",
    textAlign: "center",
    marginTop: rootStyles.spacers.large,
};
const fieldDescriptionText: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    width: "100%",
    textAlign: "left",
};
const formStyle: React.CSSProperties = {
    textAlign: "center",
    margin: "auto",
    maxWidth: 400,
    paddingLeft: rootStyles.spacers.large,
    paddingRight: rootStyles.spacers.large,
    marginTop: rootStyles.spacers.large,
};
const sectionHeaderStyle: React.CSSProperties = {
    marginBottom: rootStyles.spacers.small,
    marginTop: rootStyles.spacers.small,
};
const centeredText: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    textAlign: "center",
};
const separatorText: React.CSSProperties = {
    ...rootStyles.textStyles.label,
    fontSize: rootStyles.webFontSizes.t4,
    textAlign: "center",
    alignSelf: "center",
    color: rootStyles.colors.secondary,
    verticalAlign: "middle",
};
const roundNumericalSeparator: React.CSSProperties = {
    height: 26,
    width: 26,
    borderRadius: rootStyles.spacers.large,
    backgroundColor: rootStyles.colors.accent,
    marginTop: rootStyles.spacers.epic,
    textAlign: "center",
};
const logosContainer: React.CSSProperties = {
    justifyContent: "center",
    textAlign: "center",
    flex: 1,
    flexDirection: "row",
    marginTop: 10,
};
const viewSocialAccountContainer: React.CSSProperties = {
    justifyContent: "center",
    textAlign: "center",
    flex: 1,
    flexDirection: "row",
    marginTop: rootStyles.spacers.large,
};
const partnerLogoButton: React.CSSProperties = {
    borderWidth: 1,
    borderColor: rootStyles.colors.accent,
    cursor: "pointer",
};
const socialLogoContainer: React.CSSProperties = {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column-reverse",
    paddingLeft: 5,
    paddingRight: 5,
};
const socialLogoButton: React.CSSProperties = {
    borderColor: rootStyles.colors.accent,
    cursor: "pointer",
};
const socialLogo: React.CSSProperties = {
    height: 35,
    flex: 1,
};
const checkMarkContainer: React.CSSProperties = {
    flex: 1,
    height: 26,
    width: 26,
    borderRadius: 16,
    marginLeft: 101,
    marginTop: -45,
    backgroundColor: rootStyles.colors.secondary,
    position: "absolute",
};
const socialCheckMarkContainer: React.CSSProperties = {
    height: 26,
    width: 26,
    borderRadius: 16,
    padding: 0,
    marginLeft: 140,
    marginTop: -40,
    backgroundColor: rootStyles.colors.secondary,
    position: "absolute",
};

const partnerLogo: React.CSSProperties = {
    height: 40,
    width: 120,
    backgroundColor: rootStyles.colors.secondary,
    flex: 1,
    borderRadius: 4,
};

const centerAlign: React.CSSProperties = {
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
};
const jobTitleStyle: React.CSSProperties = {
    fontSize: webFontSizes.t2,
    margin: 0,
    padding: 0,
    ...buttonStyles.glassTitle,
};

function mapStateToProps(state: RootState): StateProps {
    return {
        signedIn: state.user.signedIn,
        error: state.error.errorStore.get(paths.provideInformation),
        claimSubmitted: state.artistSearch.claimSubmitted,
        inProgress: state.artistSearch.claimInProgress,
        selectedArtist: state.artistSearch.selectedArtist,
        oAuthClientIds: state.user.oAuthClientIds,
        socialAccountData: state.oAuth.socialAccountData,
        bundleMap: getBundleMap(state),
        stateTokens: get3PStateTokens(state),
        stateTokensInProgress: get3PStateTokensInProgress(state),
        blockInstagramButton: getBlockedInstagramButton(state),
        blockTwitterButton: getBlockedTwitterButton(state),
        blockCdBabyButton: getBlockedCdBabyButton(state),
        blockTunecoreButton: getBlockedTunecoreButton(state),
        blockTunecoreJpButton: getBlockedTunecoreJpButton(state),
        blockDistrokidButton: getBlockedDistrokidButton(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        claimArtist: (payload: claimRequestPayload) =>
            dispatch(artistClaimActions.claimArtist(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        refreshOutageStatus: () =>
            dispatch(outageStatusActions.getOutageStatus()),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        requestModal: (payload: ModalRenderFunction) =>
            dispatch(globalNotificationsActions.requestModalDisplay(payload)),
        getStateToken: (payload: GetStateTokenPayload) =>
            dispatch(oAuthActions.getStateToken(payload)),
        setHideIntercomLauncher: (payload: boolean) =>
            dispatch(oAuthActions.setHideIntercomLauncher(payload)),
        setShowIntercomMessenger: (payload: boolean) =>
            dispatch(oAuthActions.setShowIntercomMessenger(payload)),
        getSocialAccountData: (payload: GetSocialAccountDataPayload) =>
            dispatch(oAuthActions.getSocialAccountData(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProvideYourInformationScreen)
);
